import * as React from 'react';
import { graphql } from 'gatsby';
import { useMobile } from '../../../utils/use-series';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  ConceptMedia,
  CCard,
  LBanquetContact,
  AssetImage,
  CYoutube,
  CStepList,
  CFadeSlider,
  CMedia,
  CAnchorNav,
  CKv,
  CSpSliderCard,
  CSpSliderCard04,
} from '../../../components/_index';
import '../../../assets/_sass/page/propose.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: '',
            sub: '',
          },
        }}
        video={{
          src: '/assets/movie/anniversary/propose/kv.mp4',
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'お祝い・記念日',
                path: '/anniversary/',
              },
            ],
            current: {
              label: 'プロポーズ',
            },
          }}
        />
      </CJumbotron>
      <div className="p_propose">
        <section className="u_ptMedium">
          <div className="parallax p_intro"></div>
          <LWrap exClass="l_sect u_colorWhite">
            <div className="p_img_title">
              {isSp ? (
                <AssetImage
                  src="/assets/images/anniversary/propose/imagine_your_story__sp.png"
                  alt="IMAGINE YOUR STORY"
                />
              ) : (
                <AssetImage
                  src="/assets/images/anniversary/propose/imagine_your_story.png"
                  alt="IMAGINE YOUR STORY"
                />
              )}
            </div>
            <p className="c_sectLead">
              非日常の空間で、大切なあの人へ一生に一度のプロポーズ。
              <br />
              横浜ロイヤルパークホテルが贈るプレミアムなプロポーズストーリー。
              <br />
              大切な人の喜ぶ顔をイメージしながら、憧れのシチュエーションや演出をイメージしてみてください。
              <br />
              今日がおふたりの大切な記念日になりますように。
            </p>
            <CSectTitle
              exClass="u_pt80"
              title={{
                ja: '絶景ディナー × 1日1組限定プロポーズ',
                en: (
                  <>
                    LANDMARK <br className="u_sp" />
                    PROPOSE STORY
                  </>
                ),
              }}
            />
            <p className="c_sectLead">
              プロポーズの舞台は横浜ランドマークタワー。
              <br />
              絶景と美食を堪能する高層階のレストランでロマンティックな雰囲気と心地よいおもてなしでラグジュアリーなディナータイム。
              <br />
              ふたりだけの特別な空間で心に残る感動的なプロポーズを。
            </p>
            <h3 className="c_headingLv2">プロポーズ当日の流れ</h3>
            <CSpSliderCard04
              exClass="u_mb80 u_center_pc"
              col={3}
              data={[
                {
                  img: {
                    src: '/assets/images/anniversary/propose/img_chapel.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CStepList
              data={[
                {
                  title: <>STEP 1</>,
                  text: (
                    <>
                      <span className="u_fwb u_fs16">
                        ホテルロビーでお待ち合わせ　おふたりでレストランへ移動
                      </span>
                      <br />
                      横浜の街並みを一望できるレストランは、おすすめNo.1のロケーションです。
                    </>
                  ),
                },
                {
                  title: <>STEP 2</>,
                  text: (
                    <>
                      <span className="u_fwb u_fs16">
                        高層階からの絶景を眺めながらシャンパンで乾杯・優雅なディナータイム
                      </span>
                      <br />
                      68F フレンチレストラン「ル
                      シエール」ではプライベートルームの贅沢ディナープランをご用意。
                      <br />
                      ひと口ごとに幸福感で満たされていく、プロポーズの日にふさわしいディナーコースをご堪能ください。
                    </>
                  ),
                },
                {
                  title: <>STEP 3</>,
                  text: (
                    <>
                      <span className="u_fwb u_fs16">
                        メインディッシュの後に、プロポーズをするご本人様ご準備のために退室
                      </span>
                      <br />
                      専属のスタッフが自然に退室できるようお声がけいたします。事前に指輪をお預かりさせていただく事も可能です。
                    </>
                  ),
                },
                {
                  title: <>STEP 4</>,
                  text: (
                    <>
                      <span className="u_fwb u_fs16">
                      お席にいるパートナー様へサプライズプロポーズ
                      </span>
                      <br />
                      再びお席に戻り、12本のバラのブーケで感動プロポーズ。
                      <br />
                      眼下に広がる横浜の夜景が、特別な瞬間を彩ります。
                    </>
                  ),
                },
                {
                  title: <>STEP 5</>,
                  text: (
                    <>
                      <span className="u_fwb u_fs16">記念撮影</span>
                      <br />
                      一生に一度の最高の瞬間をカタチに。おふたりのかけがえのない思い出を残しましょう。
                    </>
                  ),
                },
                {
                  title: <>STEP 6</>,
                  text: (
                    <>
                      <span className="u_fwb u_fs16">
                      オリジナルカクテルで乾杯・デザートタイム
                      </span>
                      <br />
                      ご婚約を記念したオリジナルカクテルをご用意いたします。
                      <br />
                      窓の外に広がる煌びやかな夜景もまるでおふたりを祝福しているよう。
                    </>
                  ),
                },
                {
                  title: <>STEP 7</>,
                  text: (
                    <>
                      お帰りの際、オリジナルフォトフレームに入った記念写真をお渡しいたします。
                    </>
                  ),
                },
               
              ]}
            />
            <ul className="c_noteList u_mbLarge">
              <li>
              68F フレンチレストラン「ル シエール」のプロポーズプラン「Amour（アムール）-愛-」をお選びいただいた場合の一例です。プランやお客様によって当日の進行は異なります。
              </li>
              <li>
                オプションでご宿泊をお付けする事も可能です。スタッフまでご相談ください。
              </li>
              <li>
                進行内容は事前にスタッフと相談できるので、安心して当日をお迎えいただけます。
              </li>

            </ul>
          </LWrap>
        </section>

        <section className="">
          <div className="parallax p_restaurant"></div>
          <LWrap exClass="l_sect u_colorWhite">
          {isSp ? (
                <AssetImage
                  src="/assets/images/anniversary/propose/romantic_ways_to_propose__sp.png"
                  alt="IMAGINE YOUR STORY"
                />
              ) : (
                <AssetImage
                  src="/assets/images/anniversary/propose/romantic_ways_to_propose.png"
                  alt="IMAGINE YOUR STORY"
                />
              )}
            <br />
            <p className="c_sectLead u_mbExLarge">
              プロポーズに最適なステージをご用意しております。
              <br />
              おふたりにぴったりのプランとなりますようホテルスタッフがサポートいたします。
              <br />
              指輪を渡す演出やプロポーズ案などお気軽にご相談ください。
            </p>
            <CSectTitle
              exClass="small"
              title={{
                ja: '夜景を望むレストランでプロポーズ',
                en: 'RESTAURANT PLAN',
              }}
            />
            <CFadeSlider
              exClass="u_mb40"
              data={[
                {
                  img: {
                    src: '/assets/images/anniversary/propose/img_restaurant_slider.png',
                    alt: 'レストラン',
                  },
                },
              ]}
            />
            <p className="c_sectLead">
              高層階からの夜景と美食を堪能するレストラン。
              <br />
              ロマンティックな雰囲気と心地よいおもてなしで、ラグジュアリーなひとときを。
              <br />
              最上階に位置する70F スカイラウンジ「シリウス」、68F
              フレンチレストラン「ル シエール」よりお選びいただけます。
            </p>
            <CMedia
              data={[
                {
                  img: {
                    src: '/assets/images/anniversary/propose/img_restaurant.png',
                    alt: '',
                  },
                  title: (
                    <>
                      横浜ランドマークタワー最上階
                      <br />
                      横浜の絶景を望む
                      <br />
                      プレミアムプロポーズプラン
                    </>
                  ),
                  text: (
                    <>
                      最上階のスカイラウンジ「シリウス」の特等席をご用意。
                      <br />
                      ディナーコース、ハーフボトルシャンパン、デコレーションケーキ、花束、おふたりだけのオリジナルプロポーズカクテルが付いた人気のプランです。
                      <ul className="c_noteList u_mt20">
                        <li>
                          70F スカイラウンジ「シリウス」利用・1日2組様限定
                        </li>
                        <li>
                        予約受付は終了いたしました。
                        </li>
                      </ul>
                    </>
                  ),
                  // btn: {
                  //   label: 'プラン詳細',
                  //   link: {
                  //     href: '/restaurants/plan/sirius_premium_propose/',
                  //   },
                  //   color: 'bgWhite',
                  // },
                },
                {
                  img: {
                    src: '/assets/images/anniversary/propose/img_restaurant02.png',
                    alt: '',
                  },
                  title: (
                    <>
                      2人だけのプライベート個室プラン
                      <br />
                      Amour（アムール）- 愛 -
                    </>
                  ),
                  text: (
                    <>
                      シャンデリアが印象的なヨーロッパの邸宅をイメージしたフレンチレストラン。
                      <br />
                      夜景を望む個室を貸し切り、リエゾンがお出迎えからお見送りまでエスコートする、1日1組様限定のラグジュアリーなプランです。
                      <ul className="c_noteList u_mt20">
                        <li>
                          68F フレンチレストラン「ル
                          シエール」個室利用・1日1組様限定
                        </li>
                        <li>
                        予約受付は終了いたしました。
                        </li>
                        
                      </ul>
                    </>
                  ),
                  // btn: {
                  //   label: 'プラン詳細',
                  //   link: {
                  //     href: '/restaurants/plan/leciel_amour/',
                  //   },
                  //   color: 'bgWhite',
                  // },
                  reverse: true,
                },
                {
                  img: {
                    src: '/assets/images/anniversary/propose/img_restaurant03.png',
                    alt: '',
                  },
                  title: <>プロポーズプラン</>,
                  text: (
                    <>
                      3種のコースから選べるディナー。グラスシャンパン、デコレーションケーキ、花束付きのプランです。
                      <ul className="c_noteList u_mt20">
                        <li>70F スカイラウンジ「シリウス」利用</li>
                        <li>
                        予約受付は終了いたしました。
                        </li>
                      </ul>
                    </>
                  ),
                  // btn: {
                  //   label: 'プラン詳細',
                  //   link: {
                  //     href: 'https://www.tablecheck.com/shops/yrph-sirius/reserve?menu_lists[]=6413e9b4c7604d00101d3393&_ga=2.152054073.1446579787.1690160880-467371202.1672814799',
                  //     blank: true,
                  //   },
                  //   color: 'bgWhite',
                  // },
                },
                {
                  img: {
                    src: '/assets/images/anniversary/propose/img_restaurant04.png',
                    alt: '',
                  },
                  title: (
                    <>
                      プロポーズプラン
                      <br />
                      Serment（セルマン）- 誓い -
                    </>
                  ),
                  text: (
                    <>
                      「願いを込めて恋人に贈ると幸せになる」という言い伝えがある、12本のバラのブーケ付きのプランです。
                      <ul className="c_noteList u_mt20">
                        <li>68F フレンチレストラン「ル シエール」利用</li>
                        <li>
                        予約受付は終了いたしました。
                        </li>
                      </ul>
                    </>
                  ),
                  // btn: {
                  //   label: 'プラン詳細',
                  //   link: {
                  //     href: 'https://www.tablecheck.com/shops/yrph-leciel/reserve?menu_lists[]=640b0af6fd54cb0026d84a61&_ga=2.180758695.1446579787.1690160880-467371202.1672814799',
                  //     blank: true,
                  //   },
                  //   color: 'bgWhite',
                  // },
                  reverse: true,
                },
              ]}
            />
            <CAnchorNav
              data={[
                {
                  title: <>演出オプション</>,
                  link: {
                    href: '/anniversary/propose/#option',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="">
          <div className="parallax p_stay"></div>
          <LWrap exClass="l_sect u_colorWhite">
            <CSectTitle
              title={{
                ja: 'おふたりだけの空間でプロポーズ',
                en: 'STAY PLAN',
              }}
            />
            <CFadeSlider
              exClass="u_mb40"
              data={[
                {
                  img: {
                    src: '/assets/images/anniversary/propose/img_stay_slider.png',
                    alt: 'レストラン',
                  },
                },
              ]}
            />
            <p className="c_sectLead">
              横浜ランドマークタワー52Fから67Fに位置する客室。
              <br />
              さまざまな表情をお楽しみいただける高層階からの眺望も、おふたりで過ごす時間の想い出に。
              <br />
              チェックインやお食事から戻られる時間に合わせてお部屋を装飾する、サプライズ演出も可能です。
            </p>
            <CMedia
              data={[
               
                {
                  img: {
                    src: '/assets/images/anniversary/propose/img_stay02.png',
                    alt: '',
                  },
                  title: (
                    <>
                      夜空に浮かぶレストランでふたりのストーリーが始まる
                      <br />
                      ～豪華ディナー付プレミアムプラン～
                    </>
                  ),
                  text: (
                    <>
                      おふたりの記念日を特別な1日に。
                      <br />
                      客室は、窓際に配置したカウンターが特徴的な64Fのスカイリゾートフロア「アトリエ」。
                      <br />
                      ラグジュアリーな記念日にふさわしい、フルコースディナー＆朝食付きプランです。
                    </>
                  ),
                  btn: {
                    label: 'プラン詳細',
                    link: {
                      href: 'https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_yokohama&plan_groupcd=IXHY2W&form=jp',
                      blank: true,
                    },
                    color: 'bgWhite',
                  },
                  reverse: true,
                },
              ]}
            />
            <CYoutube videoId="t-IGe9cJRGQ" exClass="u_mb90" />
            <CAnchorNav
              data={[
                {
                  title: <>演出オプション</>,
                  link: {
                    href: '/anniversary/propose/#option',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="l_sect option" id="option">
          <LWrap>
            <CSectTitle
              exClass="u_colorGold small"
              title={{ en: <>OPTIONAL ITEM</> }}
            />
            <p className="c_sectLead u_tac_sp">
              プロポーズに華を添えるアイテムを
              <br className="u_sp" />
              ご用意いたします。
              <br />
              ご希望やイメージをお聞かせください。
            </p>
            <CCard
              col={2}
              data={[
                {
                  img: {
                    src: '/assets/images/anniversary/propose/img_optional.png',
                    alt: '',
                  },
                  title: <>バラの花束</>,
                  content: (
                    <>
                      サプライズにぴったり。108本のバラの花束をお渡ししてみてはいかがですか。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/propose/img_optional02.png',
                    alt: '',
                  },
                  title: <>12本のバラのブーケ</>,
                  content: (
                    <>
                      12本のバラは「感謝　誠実　幸福　信頼　希望　愛情　情熱　真実　尊敬　栄光　努力　永遠」の象徴。「これらを全てあなたに誓います。」という気持ちを込めた花束です。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/propose/img_optional03.png',
                    alt: '',
                  },
                  title: <>フラワーアレンジメント</>,
                  content: (
                    <>
                      お花を敷き詰めたようなフラワーボックス。
                      <br />
                      箱のまま飾れるのでプレゼントに最適です。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/propose/img_optional04.png',
                    alt: '',
                  },
                  title: <>バルーンデコレーション</>,
                  content: (
                    <>
                      ご宿泊のお部屋にご用意いたします。
                      <br />
                      扉を開けた時のサプライズ演出にぴったりです。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/propose/img_optional05.png',
                    alt: '',
                  },
                  title: <>フラワー＆キャンドルロード</>,
                  content: (
                    <>
                      花びらロードの先に続くテーブルにはプレゼントが。
                      <br />
                      そんなロマンティックな演出も喜ばれそうです。
                    </>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/anniversary/propose/img_optional06.png',
                    alt: '',
                  },
                  title: <>ベッドフラワー</>,
                  content: (
                    <>
                      花びらでベッドの上にハートを作ります。
                      <br />
                      さりげなくメッセージカードをしのばせてみてはいかがでしょう。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>
        {/* <section className="u_mbLarge" id="benefit">
          <LWrap exClass="p_border">
            <CSectTitle
              exClass="u_colorGold"
              title={{ en: <>WEDDING PLAN </> }}
            />
            <p className="c_sectLead u_mb0_pc">
              プロポーズの瞬間を横浜ロイヤルパークホテルで迎えてくださったおふたりへ、
              <br />
              お祝いと感謝の気持ちをこめて限定のウエディングプランをご用意しております。
              <br />
              プロポーズから結婚式まで、スタッフ一同でお手伝いいたします。
            </p>
            <CMedia
              exClass="u_mt60_pc"
              data={[
                {
                  img: {
                    src: '/assets/images/anniversary/propose/img_wedding.png',
                    alt: '',
                  },
                  title: <>ウエディング特典</>,
                  text: (
                    <>
                      感謝とお祝いの気持ちを込めて通年でご利用いただけるご優待「お料理・お飲物5％OFF」をご用意いたしました。
                      <br />
                      もちろんシーズナルプランとの併用も可能です。プロポーズからご結婚式までホテルスタッフ一同でお手伝いいたします。
                    </>
                  ),
                  btn: {
                    label: 'ウエディングプラン',
                    link: {
                      href: '/wedding/plan/5bo8v93wn/',
                    },
                  },
                },
              ]}
            />
          </LWrap>
        </section> */}
        <section className="u_mbMedium">
          <LWrap>
            <ul className="c_noteList">
              <li>画像は全てイメージです。</li>
            </ul>
          </LWrap>
        </section>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
